/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/style.scss"

const addScript = url => {
    const script = document.createElement("script")
    script.src = url
    script.async = true
    document.body.appendChild(script)
}

export const onClientEntry = () => {
    window.onload = () => {
        // addScript("https://www.provenexpert.com/widget/landing_hanseatisches-immobilienkontor-schwolle-gienke-gmbh-co-kg.js?feedback=1&avatar=0&competence=1&style=white")
        // addScript("https://fast.wistia.com/assets/external/E-v1.js")
        addScript("https://www.googletagmanager.com/gtag/js?id=AW-601692041")
    }
}